<template>
  <div class="dashboard" v-loading="loading">
    <div class="col" v-show="showDataDemo">
      <el-row class="dashboard-top">
        <el-col :span="14" class="right-line">
          <el-row>
            <el-col :span="10">
              <h4>期权激励计划统计</h4>
              <div class="card top">
                <p>计划数量(个)</p>
                <div class="nums">{{ divisionNums(this.option.number) }}</div>
              </div>
              <div class="card" style="margin-top: 18px">
                <p>总股数(股)</p>
                <div class="nums">
                  {{ divisionNums(this.option.total_qty) }}
                </div>
              </div>
            </el-col>
            <el-col :span="14" class="charts">
              <Ring
                :name="'已授予'"
                :subtext="
                  (
                    parseFloat(option.award_qty) / parseFloat(option.total_qty)
                  ).toFixed(2) *
                    100 +
                  '%'
                "
                :value="
                  (
                    (parseFloat(option.award_qty) /
                      parseFloat(option.total_qty)) *
                    100
                  ).toFixed(2)
                "
                :color1="'#FF6B99'"
                :color2="'#f0427a'"
                :color3="'#FF1960'"
                :shadowcolor="'rgba(255,107,153,0.5)'"
                :id="'sychart'"
                :subColor="'#ECF0F3'"
                :height="chartHeight"
              ></Ring>
              <div class="panels">
                <DigitalPanel
                  :title="'授予总数(股)'"
                  :color="'linear-gradient(270deg, #FF6B99 0%, #FF1960 100%)'"
                  :value="option.award_qty"
                  style="width: 50%"
                ></DigitalPanel>



                <DigitalPanel
                  :title="'剩余总数(股)'"
                  :color="'linear-gradient(#ECF0F3,#ECF0F3)'"
                  :value="option.surplus_qty"
                  style="width: 50%"
                ></DigitalPanel>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="10">
          <div class="tops bottom-line">
            <el-row>
              <el-col :span="14" class="bo">
                <Canvasdash
                  :id="'charts1'"
                  :value="
                    parseFloat(option.mature_qty) /
                    (parseFloat(option.award_qty) == 0
                      ? 1
                      : parseFloat(option.award_qty)
                    ).toFixed(2)
                  "
                  :height="rightchartheight"
                  :color1="'#F06000'"
                  :color2="'#FF9651'"
                  :shadowcolor="'rgba(255,160,97,0.5)'"
                  :shadowcolor2="'rgba(255,160,97,0.3)'"
                  :name="'已成熟'"
                ></Canvasdash>
              </el-col>
              <el-col :span="10" class="pa">
<!--                <DigitalPanel-->
<!--                  :title="'授予总数(股)'"-->
<!--                  :color="'transparent'"-->
<!--                  :value="option.award_qty"-->
<!--                ></DigitalPanel>-->
                <DigitalPanel
                    :title="'授予总数(股)'"
                    tipText="<div>统计中的“授予总数”和“成熟总数”与授予记录<br/>中的“授予股数”和“已成熟数”不是简单的汇<br />总关系。</div><div>考虑了“已终止”状态的授予记录中“已回收数”<br />对统计中“已授予数”和“已成熟数”的影响。</div>"
                    :color="''"
                    :value="option.award_qty"
                ></DigitalPanel>

                <DigitalPanel
                  :title="'成熟总数(股)'"
                  :color="'linear-gradient(90deg, #F06000 0%, #FF9651 100%)'"
                  :value="option.mature_qty"
                  style="margin-top: 22px"
                ></DigitalPanel>
              </el-col>
            </el-row>
          </div>
          <div class="tops" >
            <el-row>
              <el-col :span="14" class="bo" style="margin-top: 15px">
                <Canvasdash
                  :id="'charts2'"
                  :value="
                    parseFloat(option.actual_qty) /
                    (parseFloat(option.mature_qty) == 0
                      ? 1
                      : parseFloat(option.mature_qty)
                    ).toFixed(2)
                  "
                  :height="rightchartheight"
                  :color1="'#57EB99'"
                  :color2="'#0DCD63'"
                  :shadowcolor="'rgba(87,235,153,0.5)'"
                  :shadowcolor2="'rgba(87,235,153,0.3)'"
                  :name="'已行权'"
                ></Canvasdash>
              </el-col>
              <el-col :span="10" class="pa">
                <DigitalPanel
                  :title="'成熟总数(股)'"
                  :color="'transparent'"
                  :value="option.mature_qty"
                ></DigitalPanel>
                <DigitalPanel
                  :title="'行权总数(股)'"
                  :color="'linear-gradient(270deg, #57EB99 0%, #0DCD63 100%)'"
                  :value="option.actual_qty"
                  style="margin-top: 22px"
                ></DigitalPanel>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
      <el-row class="dashboard-top" style="margin-top: 24px">
        <el-col :span="14" class="right-line">
          <el-row>
            <el-col :span="10">
              <h4>股票激励计划统计</h4>
              <div class="card top">
                <p>计划数量(个)</p>
                <div class="nums">{{ divisionNums(stock.number) }}</div>
              </div>
              <div class="card" style="margin-top: 18px">
                <p>总股数(股)</p>
                <div class="nums">{{ divisionNums(stock.total_qty) }}</div>
              </div>
            </el-col>
            <el-col :span="14" class="charts">
              <Ring
                :name="'已授予'"
                :subtext="
                  (
                    parseFloat(stock.award_qty) / parseFloat(stock.total_qty)
                  ).toFixed(2) *
                    100 +
                  '%'
                "
                :value="
                  (
                    (parseFloat(stock.award_qty) /
                      parseFloat(stock.total_qty)) *
                    100
                  ).toFixed(2)
                "
                :color1="'#866BFF'"
                :color2="'#6e51ed'"
                :color3="'#5A39EB'"
                :shadowcolor="'rgba(134,107,255,0.5)'"
                :id="'sychart2'"
                :subColor="'#ECF0F3'"
                :height="chartHeight"
              ></Ring>
              <div class="panels">
                <DigitalPanel
                  :title="'授予总数(股)'"
                  :color="'linear-gradient(270deg, #866BFF 0%, #5A39EB 100%)'"
                  :value="stock.award_qty"
                  style="width: 50%"
                ></DigitalPanel>
                <DigitalPanel
                  :title="'剩余总数(股)'"
                  :color="'linear-gradient(#ECF0F3,#ECF0F3)'"
                  :value="stock.total_qty - stock.award_qty"
                  style="width: 50%"
                ></DigitalPanel>
              </div>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="10">
          <div class="tops center">
            <el-row>
              <el-col :span="14" class="bo">
                <Canvasdash
                  :id="'charts3'"
                  :value="
                    parseFloat(stock.actual_qty) /
                    (parseFloat(stock.award_qty) == 0
                      ? 1
                      : parseFloat(stock.award_qty))
                  "
                  :height="rightchartheight"
                  :color1="'#FF2699'"
                  :color2="'#FF77BF'"
                  :shadowcolor="'rgba(254,118,191,0.5)'"
                  :shadowcolor2="'rgba(255,119,191,0.3)'"
                  :name="'已归属'"
                ></Canvasdash>
              </el-col>
              <el-col :span="10" class="pa">
<!--                <DigitalPanel-->
<!--                  :title="'授予总数(股)'"-->
<!--                  :color="'transparent'"-->
<!--                  :value="stock.award_qty"-->
<!--                ></DigitalPanel>-->

                <DigitalPanel
                    :title="'授予总数(股)'"
                    tipText="<div>统计中的“授予总数”与授予记录中的“授予股<br/>数”不是简单的汇<br />总关系。</div><div>考虑了“已终止”状态的授予记录中“已回收数”<br />对统计中“已授予数”的影响。</div>"
                    :color="'transparent'"
                    :value="stock.award_qty"
                ></DigitalPanel>

                <DigitalPanel
                  :title="'归属总数(股)'"
                  :color="'linear-gradient(90deg, #FF2699 0%, #FF77BF 100%)'"
                  :value="stock.actual_qty"
                  style="margin-top: 22px"
                ></DigitalPanel>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>

    <Empty class="tt" v-show="!showDataDemo" />
  </div>
</template>

<script>
import Canvasdash from "@/components/charts/canvasdash";
// import DigitalPanel from "./components/DigitalPanel";
import Ring from "@/components/charts/ring";
import api from "@/api";
import Empty from "@/components/base/empty";
import { divisionNum } from "@/utils/setting";
import DigitalPanel from "@/pages/incentive/components/DigitalPanel";

export default {
  name: "Dashboard",
  components: {
    Canvasdash,
    DigitalPanel,
    Ring,
    Empty,
  },
  data() {
    return {
      // eslint-disable-next-line vue/no-reserved-keys
      chartData: [
        { type: "1-3", value: 0, color: "#478DFF" },
        { type: "4-10", value: 0, color: "#34C759" },
        { type: "已授予", value: 41.53, color: "#F8AE44" },
      ],
      chartData2: [
        { type: "1-3", value: 0, color: "#478DFF" },
        { type: "4-10", value: 0, color: "#34C759" },
        { type: "已授予", value: 41.53, color: "#5939e3" },
      ],
      subColor: "#ECF0F3",
      height: 230,
      colorList: ["#478DFF", "#34C759", "#F8AE44"],
      stock: {
        actual_qty: 0,
        award_qty: 0,
        number: 0,
        surplus_qty: 0,
        total_qty: 0,
      },
      option: {
        actual_qty: 0,
        award_qty: 0,
        mature_qty: 0,
        number: 0,
        surplus_qty: 0,
        total_qty: 0,
      },
      showDataDemo: true,
      loading: true,
      chartHeight:180,
      rightchartheight:98,
    };
  },

  created() {
    this.getData();
    let data = window.innerHeight
    console.log(window.innerHeight)
    if(data>=1280){
      this.chartHeight=280
      this.rightchartheight = 138
    }else if(data>=960){
      this.chartHeight = 240
      this.rightchartheight = 108
    }else if(data>=768){
      this.chartHeight = 180
      this.rightchartheight = 98
    }else{
      this.chartHeight = 150
      this.rightchartheight = 78
    }
  },

  methods: {
    async getData() {
      try {
        this.loading = true;
        const data = await api.dashboard.statIndex();
        // console.log(data)
        this.loading = false;
        this.stock = data.stock_stat;
        this.option = data.option_stat;
        if (this.stock.number == 0 && this.option.number == 0) {
          this.showDataDemo = false;
        }
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },

    divisionNums(e) {
      return divisionNum(e);
    },
  },
};
</script>

<style lang="scss" scoped>

/** 2022/3/29
*作者:Barney Jiang
*功能:高度自适应
*/

@media only screen and (max-height: 1280px) {
  .dashboard{
    .dashboard-top{
      height: 500px;

      .top {
        margin-top: 80px;
      }
      .tops{
        padding: 5%  32px  5%  32px;
      }
    }
  }

}
@media only screen and (max-height: 1000px) {
  .dashboard{
    .dashboard-top{
      height: 360px;

      .top {
        margin-top: 60px;
      }
      .tops{
        padding: 2%  32px  5%  32px;
      }
    }
  }

}
// 屏幕宽度小于768|默认768为移动端
@media only screen and (max-height: 770px) {
  .dashboard{
    .dashboard-top{
      height: 300px;

      .top {
        margin-top: 40px;
      }
      .tops{
        padding: 1px  32px  1%  32px;
        bo{

        }
      }
    }
  }


}






.dashboard {
  .tt {
    padding-top: 250px;
  }

  .dashboard-top {
    padding: 28px 24px;
    background: #fff;
    overflow: hidden;

    h4 {
      font-size: 20px;
      font-weight: 500;
    }



    .card {
      p {
        font-size: 14px;
        color: #898a8c;
        line-height: 22px;
        font-weight: 400;
      }

      .nums {
        font-size: 32px;
        color: rgba(51, 51, 51, 0.85);
        line-height: 48px;
        font-weight: 500;
      }
    }

    .charts {
      padding-top: 16px;
      height: 80%;

      .panels {
        display: flex;
        margin-top: 30px;
      }
    }

    .tops {
      //padding: 2%  32px  5%  32px;
      //padding-top: 10%;
      //padding-bottom: 10%;


      .pa {
        padding-left: 50px;
      }
    }

    .center {
      padding-top: 80px;
    }
  }
}
</style>
