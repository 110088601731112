var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"dashboard"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDataDemo),expression:"showDataDemo"}],staticClass:"col"},[_c('el-row',{staticClass:"dashboard-top"},[_c('el-col',{staticClass:"right-line",attrs:{"span":14}},[_c('el-row',[_c('el-col',{attrs:{"span":10}},[_c('h4',[_vm._v("期权激励计划统计")]),_c('div',{staticClass:"card top"},[_c('p',[_vm._v("计划数量(个)")]),_c('div',{staticClass:"nums"},[_vm._v(_vm._s(_vm.divisionNums(this.option.number)))])]),_c('div',{staticClass:"card",staticStyle:{"margin-top":"18px"}},[_c('p',[_vm._v("总股数(股)")]),_c('div',{staticClass:"nums"},[_vm._v(" "+_vm._s(_vm.divisionNums(this.option.total_qty))+" ")])])]),_c('el-col',{staticClass:"charts",attrs:{"span":14}},[_c('Ring',{attrs:{"name":'已授予',"subtext":(
                    parseFloat(_vm.option.award_qty) / parseFloat(_vm.option.total_qty)
                  ).toFixed(2) *
                    100 +
                  '%',"value":(
                    (parseFloat(_vm.option.award_qty) /
                      parseFloat(_vm.option.total_qty)) *
                    100
                  ).toFixed(2),"color1":'#FF6B99',"color2":'#f0427a',"color3":'#FF1960',"shadowcolor":'rgba(255,107,153,0.5)',"id":'sychart',"subColor":'#ECF0F3',"height":_vm.chartHeight}}),_c('div',{staticClass:"panels"},[_c('DigitalPanel',{staticStyle:{"width":"50%"},attrs:{"title":'授予总数(股)',"color":'linear-gradient(270deg, #FF6B99 0%, #FF1960 100%)',"value":_vm.option.award_qty}}),_c('DigitalPanel',{staticStyle:{"width":"50%"},attrs:{"title":'剩余总数(股)',"color":'linear-gradient(#ECF0F3,#ECF0F3)',"value":_vm.option.surplus_qty}})],1)],1)],1)],1),_c('el-col',{attrs:{"span":10}},[_c('div',{staticClass:"tops bottom-line"},[_c('el-row',[_c('el-col',{staticClass:"bo",attrs:{"span":14}},[_c('Canvasdash',{attrs:{"id":'charts1',"value":parseFloat(_vm.option.mature_qty) /
                    (parseFloat(_vm.option.award_qty) == 0
                      ? 1
                      : parseFloat(_vm.option.award_qty)
                    ).toFixed(2),"height":_vm.rightchartheight,"color1":'#F06000',"color2":'#FF9651',"shadowcolor":'rgba(255,160,97,0.5)',"shadowcolor2":'rgba(255,160,97,0.3)',"name":'已成熟'}})],1),_c('el-col',{staticClass:"pa",attrs:{"span":10}},[_c('DigitalPanel',{attrs:{"title":'授予总数(股)',"tipText":"<div>统计中的“授予总数”和“成熟总数”与授予记录<br/>中的“授予股数”和“已成熟数”不是简单的汇<br />总关系。</div><div>考虑了“已终止”状态的授予记录中“已回收数”<br />对统计中“已授予数”和“已成熟数”的影响。</div>","color":'',"value":_vm.option.award_qty}}),_c('DigitalPanel',{staticStyle:{"margin-top":"22px"},attrs:{"title":'成熟总数(股)',"color":'linear-gradient(90deg, #F06000 0%, #FF9651 100%)',"value":_vm.option.mature_qty}})],1)],1)],1),_c('div',{staticClass:"tops"},[_c('el-row',[_c('el-col',{staticClass:"bo",staticStyle:{"margin-top":"15px"},attrs:{"span":14}},[_c('Canvasdash',{attrs:{"id":'charts2',"value":parseFloat(_vm.option.actual_qty) /
                    (parseFloat(_vm.option.mature_qty) == 0
                      ? 1
                      : parseFloat(_vm.option.mature_qty)
                    ).toFixed(2),"height":_vm.rightchartheight,"color1":'#57EB99',"color2":'#0DCD63',"shadowcolor":'rgba(87,235,153,0.5)',"shadowcolor2":'rgba(87,235,153,0.3)',"name":'已行权'}})],1),_c('el-col',{staticClass:"pa",attrs:{"span":10}},[_c('DigitalPanel',{attrs:{"title":'成熟总数(股)',"color":'transparent',"value":_vm.option.mature_qty}}),_c('DigitalPanel',{staticStyle:{"margin-top":"22px"},attrs:{"title":'行权总数(股)',"color":'linear-gradient(270deg, #57EB99 0%, #0DCD63 100%)',"value":_vm.option.actual_qty}})],1)],1)],1)])],1),_c('el-row',{staticClass:"dashboard-top",staticStyle:{"margin-top":"24px"}},[_c('el-col',{staticClass:"right-line",attrs:{"span":14}},[_c('el-row',[_c('el-col',{attrs:{"span":10}},[_c('h4',[_vm._v("股票激励计划统计")]),_c('div',{staticClass:"card top"},[_c('p',[_vm._v("计划数量(个)")]),_c('div',{staticClass:"nums"},[_vm._v(_vm._s(_vm.divisionNums(_vm.stock.number)))])]),_c('div',{staticClass:"card",staticStyle:{"margin-top":"18px"}},[_c('p',[_vm._v("总股数(股)")]),_c('div',{staticClass:"nums"},[_vm._v(_vm._s(_vm.divisionNums(_vm.stock.total_qty)))])])]),_c('el-col',{staticClass:"charts",attrs:{"span":14}},[_c('Ring',{attrs:{"name":'已授予',"subtext":(
                    parseFloat(_vm.stock.award_qty) / parseFloat(_vm.stock.total_qty)
                  ).toFixed(2) *
                    100 +
                  '%',"value":(
                    (parseFloat(_vm.stock.award_qty) /
                      parseFloat(_vm.stock.total_qty)) *
                    100
                  ).toFixed(2),"color1":'#866BFF',"color2":'#6e51ed',"color3":'#5A39EB',"shadowcolor":'rgba(134,107,255,0.5)',"id":'sychart2',"subColor":'#ECF0F3',"height":_vm.chartHeight}}),_c('div',{staticClass:"panels"},[_c('DigitalPanel',{staticStyle:{"width":"50%"},attrs:{"title":'授予总数(股)',"color":'linear-gradient(270deg, #866BFF 0%, #5A39EB 100%)',"value":_vm.stock.award_qty}}),_c('DigitalPanel',{staticStyle:{"width":"50%"},attrs:{"title":'剩余总数(股)',"color":'linear-gradient(#ECF0F3,#ECF0F3)',"value":_vm.stock.total_qty - _vm.stock.award_qty}})],1)],1)],1)],1),_c('el-col',{attrs:{"span":10}},[_c('div',{staticClass:"tops center"},[_c('el-row',[_c('el-col',{staticClass:"bo",attrs:{"span":14}},[_c('Canvasdash',{attrs:{"id":'charts3',"value":parseFloat(_vm.stock.actual_qty) /
                    (parseFloat(_vm.stock.award_qty) == 0
                      ? 1
                      : parseFloat(_vm.stock.award_qty)),"height":_vm.rightchartheight,"color1":'#FF2699',"color2":'#FF77BF',"shadowcolor":'rgba(254,118,191,0.5)',"shadowcolor2":'rgba(255,119,191,0.3)',"name":'已归属'}})],1),_c('el-col',{staticClass:"pa",attrs:{"span":10}},[_c('DigitalPanel',{attrs:{"title":'授予总数(股)',"tipText":"<div>统计中的“授予总数”与授予记录中的“授予股<br/>数”不是简单的汇<br />总关系。</div><div>考虑了“已终止”状态的授予记录中“已回收数”<br />对统计中“已授予数”的影响。</div>","color":'transparent',"value":_vm.stock.award_qty}}),_c('DigitalPanel',{staticStyle:{"margin-top":"22px"},attrs:{"title":'归属总数(股)',"color":'linear-gradient(90deg, #FF2699 0%, #FF77BF 100%)',"value":_vm.stock.actual_qty}})],1)],1)],1)])],1)],1),_c('Empty',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showDataDemo),expression:"!showDataDemo"}],staticClass:"tt"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }