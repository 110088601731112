<template>
  <div class="digital-panel">
    <div class="title">
      <div class="circle" :style="[{ 'background-image': color }]"></div>
      <div class="text flex-h flex-vc">
        {{ title
        }}<tip-text
          v-if="tipText"
          :tip-text="tipText"
          :size="12"
          class="tip-text"
        />
      </div>
    </div>
    <div :class="min ? 'value2' : 'value'">
      {{ divisionNums(value) }}
    </div>
  </div>
</template>

<script>
import { divisionNum } from "@/utils/setting";

export default {
  props: {
    // 文本内容
    title: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    color: {
      type: String,
      default: "#ffffff",
    },
    min: {
      type: Boolean,
      default: false,
    },
    tipText: {
      type: String,
      default: "",
    },
  },
  computed: {},
  methods: {
    divisionNums(e) {
      return divisionNum(e);
    },
  },
};
</script>

<style lang="scss" scoped>
.digital-panel {
  .title {
    height: 22px;
    line-height: 22px;
    color: #898a8c;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    width: 100%;

    .circle {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      margin-right: 8px;
      margin-top: 6px;
    }
    .text {
    }
  }
  .value {
    margin-left: 18px;
    font-size: 20px;
    color: rgba(51, 51, 51, 0.85);
    line-height: 30px;
    font-weight: 500;
    text-align: left;
  }

  .value2 {
    margin-left: 18px;
    font-size: 18px;
    color: rgba(51, 51, 51, 0.85);
    line-height: 30px;
    font-weight: 500;
    text-align: left;
  }
  /deep/ .tip-text {
    margin-bottom: 0.1em;
    margin-left: 4px;
  }
}
</style>
